import { render, staticRenderFns } from "./AreaEditPresence.vue?vue&type=template&id=429e4f79&scoped=true&"
import script from "./AreaEditPresence.vue?vue&type=script&lang=ts&"
export * from "./AreaEditPresence.vue?vue&type=script&lang=ts&"
import style0 from "./AreaEditPresence.vue?vue&type=style&index=0&id=429e4f79&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "429e4f79",
  null
  
)

/* custom blocks */
import block0 from "./AreaEditPresence.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QToggle,QItem,QItemSection,QCheckbox,QSelect,QBtn,QInnerLoading,QSpinner,QPage,QLayout,QChip});
